<template>
    <div style=" background-color: white;height: 100vh;display: flex;flex-direction: row;justify-content: center;align-items: center;">

        <div style="width: 5%;">
            <img src="../../assets/images/loading.gif"  style="width: 100%" />
        </div>
    </div>
</template>
<script>
    import {redirectUri} from "../../const/redirectUri";
    import {redirect2LoginPage} from "../../const/redirectUri";
    import {doReSetStorage} from "../../api/auth";

    export default {
        beforeMount() {
            let token = this.$route.query.token;
            if (token!=null && token!=''){
              localStorage.setItem('token',token)
               let onAuhSuccess = (res) => {
                console.log('authRes',res)
                 let formSource = sessionStorage.getItem('formSource') || this.$route.query.formSource;
                 if (res.data.code == 0 && res.data.data && res.data.data.languageCode){
                   sessionStorage.setItem('systemLanguage',res.data.data.languageCode);
                   this.$i18n.locale = res.data.data.languageCode;
                   console.log('formSource 25',formSource)
                   if (formSource === 'wechatAccount'){
                     this.$router.push({ path: "/wechatBasicInfo",query:{
                         formSource
                       }})
                     sessionStorage.removeItem('formSource')
                   } else {
                     this.$router.push({ name: "botIndex"})
                   }
                 } else {
                   this.FetchGet("/portal-api/company/getUserCompanyModule").then(res => {
                     if (res.code === "0" && res.data){
                       if (res.data.systemLanguage){
                         sessionStorage.setItem('systemLanguage',res.data.systemLanguage);
                         this.$i18n.locale = res.data.systemLanguage;
                       }
                     }
                     if (formSource === 'wechatAccount'){
                       this.$router.push({ path: "/wechatBasicInfo",query:{
                           formSource
                       }})
                       sessionStorage.removeItem('formSource')
                     } else {
                       this.$router.push({ name: "botIndex"})
                     }
                   })
                 }

              };
               let onAuhException = () => {this.$message({  type: 'error',   message: 'token校验异常,请尝试退出重新登录',  duration:2000   });};
               doReSetStorage(onAuhSuccess,onAuhException,token);
            }
            else {
                redirect2LoginPage();
            }
        }
    }
</script>