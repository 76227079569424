<template>
    <div @click="lookImage">
      <vue-markdown class="mark_down"
                    :source="typedContent"
                    :ref="'markdown'+ msgId"
      >
      </vue-markdown>
      <div v-if="showPreview">
        <img-view :url-list="imgList" @closeViewer="closeViewer"></img-view>
      </div>
    </div>
  
  </template>
  
  <script>
  import VueMarkdown from 'vue-markdown';
  import ImgView from "./imgView";
  
  export default {
    name: "markDownText",
    data(){
      return{
        typedContent:"",
        typingSpeed:15,
        showPreview:false,
        imgList:[]
      }
    },
    props:{
      chainValues:{
        type:String,
        default:""
      },
      msgId:{
        type:String,
        default:""
      },
      isHistory:{
        type:Boolean,
        default:false
      }
    },
    components:{
      ImgView,
      VueMarkdown
    },
    mounted() {
      this.$nextTick(() => {
        // let ref = 'markdown' + this.msgId
        // const el = this.$refs[ref].$el;
        // if (this.isHistory){
        //   el.innerHTML = this.chainValues;
        // } else {
        //   new Typed(el, {
        //     strings: [this.chainValues],
        //     typeSpeed: 30,
        //     showCursor: false
        //   })
        // }
        if (this.isHistory){
          this.typedContent = this.chainValues
        } else {
          this.startTypingEffect();
        }
      })
    },
    methods:{
      startTypingEffect() {
        let i = 0;
        const interval = setInterval(() => {
          if (i < this.chainValues.length) {
            this.typedContent += this.chainValues.charAt(i);
            i++;
          } else {
            clearInterval(interval);
          }
        }, this.typingSpeed);
      },
      lookImage(e){
        let previewImageUrl = ""
        console.log('e.target',e.target)
        if (e.target.localName == 'img'){
          previewImageUrl = e.target.currentSrc;
          this.showPreview = true;
        }
        let richtext = JSON.parse(JSON.stringify(this.typedContent))
        this.imgList = [];
        richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
          this.imgList.push(capture);
        });
        /*当前点击的图片作为第一个图片*/
        let index = this.imgList.indexOf(previewImageUrl);
        this.imgList.splice(index,1);
        this.imgList.unshift(previewImageUrl);
      },
      closeViewer(){
        this.showPreview = false;
      }
    }
  };
  </script>
  
  <style scoped lang="less">
  .mark_down{
    line-height: 25px;
    /deep/p{
      margin-bottom: 14px;
    }
    /deep/p:only-child{
      margin: 0!important;
    }
    /deep/p:last-child{
      margin-bottom: 0!important;
    }
    /deep/ol,ul{
      li{
        margin: 14px 0!important;
      }
    }
    /deep/img{
      max-width: 400px;
    }
  }
  </style>