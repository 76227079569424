<template>
	<div class="image-view" >
		<el-image-viewer 
			:class="['my-image-view',isCanDel ? 'max-w' : 'min-w']"
			ref="imageViewer" 
			style="z-index:9002" 
			:on-close="closeViewer" 
			:url-list="previewList" />
		<div class="preview-box">
			<i class="full-screen-btn iconfont guoran-fangda" @click="handleFullScreen"></i>
			<i class="delete-image-btn iconfont guoran-tongyichicun-18-13-shanchu" @click="deleteImage" v-if="isCanDel"></i>
			<div  class="full-screen-image" v-show="isFullScreen">
				<el-image
					:src="currentImage.imageUrl">
				</el-image>img
			</div>
			
		</div>
	</div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
	name: "imageView",
	components: { ElImageViewer },
	data(){
		return {
            isFullScreen: false, //	定义一个判断当前是否为全屏的变量

		}
	},
	props:{
		// 预览数组
		previewList:{
			type:Array,
			default(){
				return []
			}
		},
		// 是否显示预览组件
		showViewer:{
			type:Boolean,
			default:false
		},
		// 当前预览的大图
		currentImage:{
			type:Object,
			default(){
				return {}
			}
		},
		// 是否显示删除按钮
		isCanDel:{
			type:Boolean,
			default:true
		}
	},
	watch:{
		previewList:{
			handler(n){
				console.log(n,'--nnnn');
			},
			deep:true
		},
	},
	mounted(){
		console.log('预览组件-----mounted');
		this.setBtnStyle();
		window.addEventListener('resize', this.isFullScreenFn);
        window.addEventListener('keydown', this.bindKeydown);
		this.$nextTick(() => {
			let body = document.querySelector("body");
			if (body.append) {
				body.append(this.$el);
			} else {
				body.appendChild(this.$el);
			}
		});
	},
	beforeDestroy(){
		console.log('预览组件----beforeDestroy');
		
        window.removeEventListener('resize', this.isFullScreenFn);
        window.removeEventListener('keydown', this.bindKeydown);
    },
	methods: {
		// 设置操作按钮样式
		setBtnStyle(){
			this.$nextTick(() => {
				// let imgDom = null;
				// imgDom = document.querySelector('.my-image-view .el-image-viewer__canvas img');
				// imgDom.setAttribute('src',this.currentImage.imageUrl);
					//大图预览从点击的那张开始
				// if(this.previewList.length > 1){
					let tempImgList = [...this.previewList];
					let temp = [];
					for (let i = 0; i < this.currentImage.sortNum; i++) {
						temp.push(tempImgList.shift());
					}
					this.$emit('update:previewList', tempImgList.concat(temp))
				// }
			
				let screenDom = document.querySelector('.el-image-viewer__btn .el-image-viewer__actions__inner .el-icon-zoom-in');
				let leftDom = document.querySelector('.el-image-viewer__btn .el-image-viewer__actions__inner .el-icon-refresh-left')
				let screenDomLeft = Math.ceil(screenDom.getBoundingClientRect().left);
				let leftDomLeft = Math.ceil(leftDom.getBoundingClientRect().left);
				document.querySelector('.el-image-viewer__btn .el-image-viewer__actions__inner .el-icon-full-screen').style.display = 'none';
				document.querySelector('.el-image-viewer__btn .el-image-viewer__actions__inner .el-icon-refresh-right').style.display = 'none';
			
				if(this.isCanDel){
					document.querySelector('.full-screen-btn').style.left = screenDomLeft + 50 + 'px';
					document.querySelector('.delete-image-btn').style.left = leftDomLeft + 50 + 'px';
				} else {
					document.querySelector('.full-screen-btn').style.left = screenDomLeft + 50 + 'px';
				}
				leftDom.style.left = '164px';
				console.log(this.previewList,'this.previewList');
				// 切换图片按钮绑定事件
				if(this.previewList.length > 1){
					console.log(document.querySelector('.el-image-viewer__wrapper .el-image-viewer__next'),'下一张');
					console.log(document.querySelector('.el-image-viewer__wrapper .el-image-viewer__prev'),'上一张');
					// 下一张
					document.querySelector('.el-image-viewer__wrapper .el-image-viewer__next').onclick = () => {
						// this.getImgUrl();
						this.$emit('switchImgage')
					}
					// 上一张
					document.querySelector('.el-image-viewer__wrapper .el-image-viewer__prev').onclick = () => {
						// this.getImgUrl();
						this.$emit('switchImgage')
					}
				}
				
			})
		},
		// 全屏
        handleFullScreen(){
            let element  = document.querySelector('.preview-box .full-screen-image')
            let requestMethod = element.requestFullScreen || //W3C
                element.webkitRequestFullScreen || //Chrome
                element.mozRequestFullScreen || //FireFox
                element.msRequestFullScreen; //IE11
            if (requestMethod) {
                requestMethod.call(element);
            } else if (typeof window.ActiveXObject !== "undefined") { //for Internet Explorer
                let wscript = new ActiveXObject("WScript.Shell");
                if (wscript !== null) {
                    wscript.SendKeys("{F11}");
                }
            }
            setTimeout(() => {
                this.isFullScreen = this.isFullScreenFn();
                this.$forceUpdate(); // 必须加 ,否则视图不更新
                console.log(this.isFullScreen,'监听是否全屏状态');
                element.style.position = 'fixed'
                element.style.left = 0
                element.style.top = 0
                element.style.height = '100%';
                this.$nextTick(() => {
                    let img = document.querySelector('.preview-box .full-screen-image .el-image');
                    img.style.height = '100%';
                })
            },100)
        },
		// 监听是否全屏状态
        isFullScreenFn(event) {
            // 利用屏幕分辨率和window对象的内高度来判断兼容IE
            let winFlag = window.innerHeight === window.screen.height
            // 利用window全屏标识来判断 -- IE无效
            let isFull = window.fullScreen || document.webkitIsFullScreen
            let flag = false;
            if (isFull === undefined) {
                flag = winFlag
            } else {
                flag = winFlag || isFull
            }
            this.isFullScreen = flag;
            console.log(flag,'flag');
            return flag
        },
		// 全屏查看大图时 , 快捷键的处理
        bindKeydown(e){
			this.$emit('bindKeydown')
        },
		closeViewer() {
			this.$emit('update:showViewer',false)
			// 右上方关闭按钮销毁该组件
			// document.body.removeChild( document.getElementsByClassName('el-image-viewer__wrapper')[0])
		},
		deleteImage(){
			this.$emit('deleteImage')
		}
	}
}
</script>
<style lang="less">
.my-image-view{
	// z-index: inherit !important;
	&.max-w{
		.el-image-viewer__actions{
			width: 260px;
		}
	}
	&.min-w{
		.el-image-viewer__actions{
			width: 210px;
		}
	}
	.el-image-viewer__actions__inner{
		display: block;
		line-height: 44px;
		i{
			display: inline-block;
			width: 47px;
		}
		.el-icon-zoom-out{
			// margin-right: 20px;
		}
		.el-image-viewer__actions__divider{
			display: none;
		}
		.el-icon-refresh-left{
			position: fixed;
			top: 10px;
		}
	}
	.el-image-viewer__btn{
		background: #606266;
		color: #fff;
		font-size: 24px;
	}
}
</style>
<style scoped lang="less">
.image-view{
	/deep/ .el-image-viewer__close {
		color: white;
	}
	 .preview-box{
		// position: fixed;
		// bottom: 30px; 
		// left: 400px;
		// z-index:3333;
		.full-screen-btn,.delete-image-btn{
			position: fixed;
			bottom: 44px; 
			color: #eef0f0;
			cursor: pointer;
			z-index:3001;
		}
		.delete-image-btn{
			font-size: 18px;
		}
	}
}
</style>