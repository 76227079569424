<template>
    <div>
      <el-image-viewer
          :zIndex="999999"
          :on-close="closeViewer"
          :url-list="urlList"
      />
    </div>
  </template>
  
  <script>
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  export default {
    name: "imgView",
    components: {ElImageViewer},
    data(){
      return {
  
      }
    },
    props:['urlList'],
    methods:{
      closeViewer(){
        // 关闭组件后从父级dom销毁这个挂载
        if (this.$el.parentNode){
          this.$el.parentNode.removeChild(this.$el);
        }
        this.$emit('closeViewer');
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>