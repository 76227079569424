<template>
    <div></div>
</template>
<script>
    import {redirectUri} from "../../const/redirectUri";
    import { doReSetStorage } from "../../api/auth";

    export default {
        beforeCreate() {

            console.log('currentEditor.env',process.env);
            // console.log('currentEditor.env.CONFIG_ENV',process.env.VUE_APP_CONFIG_ENV);
          let uid = localStorage.getItem('_uid')
          let mainId = localStorage.getItem('_mainId')
          if (!uid || !mainId){
            let redirect = window.location.origin + '/#/auth';
            console.log('redirect:', redirect)
            if(window.location.origin == 'https://jd-open.askbot.cn' || window.location.origin == 'https://jd-portal.askbot.cn') {
              window.location = 'https://jd-signin.askbot.cn' + '?redirect_uri=' +  encodeURIComponent(redirect)
            } else if(window.location.origin == 'https://open.ebot.isheely.com' || window.location.origin == 'https://portal.ebot.isheely.com') {
              window.location = 'https://signin.ebot.isheely.com' + '?redirect_uri=' + encodeURIComponent(redirect)
            } else {
              window.location = redirectUri[process.env.VUE_APP_CONFIG_ENV].sso + '?redirect_uri=' + encodeURIComponent(redirect)
            }
            return
          }
            this.FetchGet(this.requestUrl.auth.logged)
                .then(async (res) => {
                  console.log('logged-success',res);
                  let token = localStorage.getItem("token")
                  console.log('token 31',token)
                  if (token != null && token != ''){
                    await this.$http.get('/api/sso/auth?' + token).then(res => {
                      if (res.data.code == 0 && res.data.data && res.data.data.languageCode){
                        sessionStorage.setItem('systemLanguage',res.data.data.languageCode);
                        this.$i18n.locale = res.data.data.languageCode;
                      } else {
                        this.FetchGet("/portal-api/company/getUserCompanyModule").then(res => {
                          if (res.code === "0" && res.data){
                            if (res.data.systemLanguage){
                              sessionStorage.setItem('systemLanguage',res.data.systemLanguage);
                              this.$i18n.locale = res.data.systemLanguage;
                            }
                          }
                        })
                      }
                    })
                  }
                  sessionStorage.setItem('logged', true)
                  let query = sessionStorage.getItem('FromWorkOrderToChatFlow');
                  if(query) {
                    this.$router.push({
                      path: "/main/chatFlow",
                      query : JSON.parse(query)
                    })
                    sessionStorage.removeItem('FromWorkOrderToChatFlow')
                  } else  {
                    let formSource = sessionStorage.getItem('formSource') || this.$route.query.formSource;
                    console.log('formSource 58',formSource)
                    if (formSource === 'wechatAccount'){
                      this.$router.push({ path: "/wechatBasicInfo",query:{
                          formSource
                        }})
                      sessionStorage.removeItem('formSource')
                    } else {
                      this.$router.push({ name: "botIndex"})
                    }
                  }
                })
                .catch((error) => {
                  console.log('logged-error',JSON.stringify(error));
                  if(error.status === 510){
                    if(window.location.origin == 'https://open.ebot.isheely.com') {
                      location.href = "https://portal.ebot.isheely.com/#/expired"
                    } else {
                      location.href = process.env.VUE_APP_EXPIRED_URL;
                    }
                  } else {
                    let redirect = window.location.origin + '/#/auth';
                    console.log('redirect:', redirect)
                    if(window.location.origin == 'https://jd-open.askbot.cn' || window.location.origin == 'https://jd-portal.askbot.cn') {
                      window.location = 'https://jd-signin.askbot.cn' + '?redirect_uri=' +  encodeURIComponent(redirect)
                    } else if(window.location.origin == 'https://open.ebot.isheely.com' || window.location.origin == 'https://portal.ebot.isheely.com') {
                      window.location = 'https://signin.ebot.isheely.com' + '?redirect_uri=' + encodeURIComponent(redirect)
                    }else {
                      console.log('debugger');
                      location.href = redirectUri[process.env.VUE_APP_CONFIG_ENV].sso + '?redirect_uri=' + encodeURIComponent(redirect)
                    }
                  }
                });
        }
    }
</script>