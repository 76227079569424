<template>
    <div class="adv-search-cpnt">
        <div class="adv-search-cpnt-content">
            <el-row>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('dialogues.consultationTime')}}</div>
                        <div class="adv-s-cell-b">
                            <!-- <el-date-picker ref="lastMsgTimePicker" prefix-icon="guoran-a-18-15 iconfont"
                                v-model="lastMsgTime" type="daterange" align="right" :unlink-panels="true"
                                range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                                :picker-options="pickerOptions">
                            </el-date-picker> -->
                            <gr-datepiker ref="fastChooseDatePicker" v-model="lastMsgTime" :isSwitch="false">
                            </gr-datepiker>
                        </div>
                    </div>
                </el-col>

            </el-row>
        </div>
        <div class="adv-s-footer">
            <div style="width:1px;height:1px;" class="hidden-dom"></div>
            <el-button size="small" @click="closeAdvSerch">{{$t('common.cancel')}}</el-button>
            <el-button size="small" @click="submitAdvSerch" type="primary">{{$t('advSearch.screen')}}</el-button>
        </div>
    </div>
</template>

<script>
import GrDatepiker from "./GrDatepiker.vue";
export default {
    props: ["botId", "advSearchData", 'advSearchdrawer'],
    data() {
        return {

            lastMsgTime: "",
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨日",
                        onClick(picker) {
                            var day1 = new Date();
                            day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
                            var s1 =
                                day1.getFullYear() +
                                "-" +
                                (day1.getMonth() + 1) +
                                "-" +
                                day1.getDate();

                            const end = new Date(s1) + 24 * 60 * 60 * 1000;
                            const start = new Date(s1);
                            // start.setTime(
                            //     start.getTime() - 3600 * 1000 * 24 * 90
                            // );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "今日",
                        onClick(picker) {
                            var day1 = new Date();
                            day1.setTime(day1.getTime());
                            var s1 =
                                day1.getFullYear() +
                                "-" +
                                (day1.getMonth() + 1) +
                                "-" +
                                day1.getDate();
                            const end = new Date();
                            const start = new Date(s1);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "近7日",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "近30日",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
        };
    },
    components: {
        GrDatepiker
    },
    watch: {
        advSearchdrawer(n) {
            if (n) {
                if (this.advSearchData.orgnation && this.advSearchData.orgnation.length > 0) {
                    this.memberInfo4Client.clientInfo = this.advSearchData.orgnation;
                }
            }
        }
    },
    mounted() {
        if (this.advSearchData.lastMsgTime) {
            this.lastMsgTime = this.advSearchData.lastMsgTime;
        } else {
            this.lastMsgTime = "";
        }
    },
    methods: {
        closeAdvSerch() {
            this.$emit("closeAdvSerch");
        },
        clearAdvConditionAll() {
            this.lastMsgTime = "";
            this.$refs.fastChooseDatePicker.value = "";
        },
        clearAdvConditionCell(type) {
            switch (type) {
                case "lastMsgTime":
                    this.lastMsgTime = "";
                    this.$refs.fastChooseDatePicker.value = "";
                    break;
                default:
                    break;
            }
        },
        submitAdvSerch() {
            let param = {};
            if (this.lastMsgTime && this.lastMsgTime != "" && this.lastMsgTime.length > 0) {
                param.lastMsgTime = this.lastMsgTime;
            }
            this.$emit("submitAdvSerch", JSON.stringify(param));
        },
    },
};
</script>
<style lang="less">
.el-cascader-menu__wrap {
    height: 204px !important;
}
</style>
<style lang="less" scoped>
.adv-search-cpnt {
    .adv-search-cpnt-content {
        padding: 48px 48px 25px 48px;
        border-bottom: 1px solid #E0E6F7;
    }

    .el-col-12:first-child {
        margin-right: 22px;
        width: 436px;
    }

    .el-row:last-child {
        .adv-search-cell {
            margin-bottom: 0;

            .adv-s-cell-b {
                height: 38px;
                background-color: #FBFCFD;
                display: flex;
                align-items: center;
                padding: 0 10px;
                border-radius: 5px;
            }
        }
    }

    .adv-search-cell {
        line-height: 42px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 20px;

        .adv-s-cell-t {
            width: 120px;
            height: 15px;
            line-height: 15px;
            flex: none;
            text-align: left;
            color: #616161;
            margin-bottom: 10px;
        }

        .adv-s-cell-b {
            text-align: left;
            flex: auto;

            .el-date-picker {
                width: 100% !important;
            }

            /deep/.el-select,
            /deep/.el-cascader,
            /deep/.el-input,
            /deep/.el-date-editor {
                width: 100%;
                height: 38px !important;

                .el-input__inner,
                .el-cascader__search-input,
                .el-range-input {
                    &::-webkit-input-placeholder {
                        color: #A9B3C6 !important;
                    }
                }
            }

            .el-input {
                height: 38px !important;

                .el-input__inner {
                    height: 38px !important;

                    &::-webkit-input-placeholder {
                        color: #A9B3C6 !important;
                    }
                }

            }

            /deep/.el-radio-group {
                display: flex;
                align-items: center;

                .el-radio {
                    display: flex;
                    align-items: center;

                    .el-radio__input,
                    .el-radio__inner {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .adv-s-footer {
        padding-right: 15px;
        height: 66px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .el-button--default,
        .el-button--primary {
            width: 99px;
            height: 37px;
            background: #366AFF;
            border-radius: 19px;
            line-height: 37px;
            color: #FFFFFF;
            padding: 0;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .el-button--default {
            background: #FFFFFF;
            border: 2px solid #E0E6F7;
            color: #000000;
        }
    }
}
</style>